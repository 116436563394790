import "./App.css";
import { Route, Routes } from "react-router-dom";
import { useCallback, useEffect } from "react";
import Admin from "./Component/Pages/Admin";
import Login from "./Component/Pages/Login";
import PrivateRoute from "./util/PrivateRoute";
import { useDispatch } from "react-redux";
import "./assets/css/responsive.css";
import { LOGIN_ADMIN } from "./Component/store/admin/admin.type";
import ForgotPassword from "./Component/Pages/ForgotPassword";
import SetPassword from "./Component/Pages/SetPassword";
import axios from "axios";
function App() {
  const dispatch = useDispatch();
  const key = sessionStorage.getItem("key");
  const token = sessionStorage.getItem("token");

  const sessionTimeout = 20 * 60 * 1000; // 5 minutes in milliseconds
  let activityTimeout;

  const resetTimeout = useCallback(() => {
    if (activityTimeout) clearTimeout(activityTimeout);
    activityTimeout = setTimeout(() => {
      sessionStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }, sessionTimeout);
  }, [activityTimeout, sessionTimeout]);

  const handleActivity = () => {
    resetTimeout();
  };

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: LOGIN_ADMIN, payload: token });
  }, [token, key, dispatch]);

  useEffect(() => {
    console.log("sessionTimeout", sessionTimeout);

    // Set initial timeout
    resetTimeout();

    // Add event listeners to track user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
      if (activityTimeout) clearTimeout(activityTimeout);
    };
  }, [resetTimeout]);

  axios.interceptors.response.use(
    (response) => {
      return response; // If the request succeeds, just return the response
    },
    (error) => {
      // if (error.response && error.response.status === 403) {
        
      //   // Handle 403 error: automatic logout
      //   sessionStorage.clear();
      //   localStorage.clear();
      //   axios.defaults.headers.common["key"] = "";
      //   axios.defaults.headers.common["Authorization"] = "";
      //   window.location.href = "/";
      // } 
      // else if (error.response && error.response.status === 500) {
      //   
      //   sessionStorage.clear();
      //   localStorage.clear();
      //   axios.defaults.headers.common["key"] = "";
      //   axios.defaults.headers.common["Authorization"] = "";
      //   window.location.href = "/";
      // }
      return Promise.reject(error); // For other errors, reject the promise
    }
  );

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/changePassword" element={<SetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
